//
// Environment
//


//
// Config values
//

$platform-name: 'carmarthendomesticabuseservice';


//
// Colours
//

$brand-primary: #61387c;
$brand-secondary: #00aca7;

$donate-colour: $brand-primary;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 960px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo-width: 290px;
$logo-height: 90px;


//
// Typography
//

$font-family-base: 'Nexa W04', Arial, sans-serif;

$font-weight-normal: normal !default;
$font-weight-bold: 700;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em; // This will only affect headings within post content
$headings-font-family: 'Averia Sans Libre', cursive;

// Links
$link-hover-decoration: none;

// Icon font


//
// Buttons
//

$btn-background-colour: $brand-secondary;
$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-border-radius: 3px;

// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-box-shadow: 0 0 20px rgba(black, 0.1);
$card-border-radius: 3px;
$card-heading-font-size: $font-size-h4;

// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: .7 !default;

$event-card-floating-date: true;
$event-card-floating-date-border-radius: 3px;

//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//


// Header content

// Tagline
$tagline-enabled: true;
$tagline-above-cta-breakpoint: 1600px;
$tagline-font-size: $font-size-h3;

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

$nav-search-button-icon-colour: #fff;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: white;
$nav-top-level-item-hover-background-colour: $brand-secondary;

// Submenus
$nav-submenu-background-colour: $brand-secondary;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-colour: white;

// Burger button
$burger-btn-bar-breakpoint: map-get($breakpoints, sm); // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: center; // left, center, right
// $nav-normal-max-width: 100%; // $container-max-width


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
// $carousel-max-width: 100%;
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-border-radius: 3px;
$carousel-margin-bottom: $spacer * 3;


// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: transparent;
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-icon-font-size: 2em;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-margin-top: 0;
$home-intro-background-colour: $brand-secondary;
$home-intro-background-image: 'homeIntro.png';
$home-intro-background-image-opacity: 1;
$home-intro-padding-y: $spacer * 3;



//
// Home features
//
$home-features-background-colour: $grey-lightest;
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 4;



//
// Impact stats
//
$impact-stats-background-image: 'stats.png';
$impact-stats-background-image-opacity: 1;


// Heading
$impact-stats-heading-margin-bottom: $spacer;

// Individual stat

// Figure

// Summary


//
// Home feeds
//


// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-prefab: 6;
$footer-text-align: center;

// Footer admin links

// Newsletter
$newsletter-background-colour: $brand-secondary;
$newsletter-button-background-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 3;
$page-title-colour: $brand-primary;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts
$sidebar-layout: above;

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//

$blockquote-background-colour: $brand-secondary;


//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//


// Donation amounts


//
// Quick giving panel
//

$quick-giving-background-colour: $brand-secondary;
$quick-giving-donation-amount-background-colour: white;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;


//
// Home quick giving panel
//
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-donation-amount-background-colour: white;
$home-quick-giving-donation-amount-selected-background-colour: $brand-primary;
$home-quick-giving-heading-colour: white;
$home-quick-giving-donate-btn-background-colour: $brand-primary;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//